import React from 'react'

import { Button } from 'react-bootstrap';

import Statisticsimg from '../../assets/images/Statistics/images.png';

export default function Statistics() {
    return (
        <section class="pt-5 bg-ash">
        <div class="container">
            <div class="row gx-1 mb-4 justify-content-center align-items-center">
                <div class="col-md-5 text-center">
                <iframe class="iframe" width="360" height="200" src="https://www.youtube.com/embed/SquPoVEN7rM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div class="col-md-6 offer-desc">
                    <h3 class=" stat-head">Statistics</h3>
                    <p class="w-75 stat-desc">Heartfulness Education Trust has been working towards providing
                        educational services across India for people from
                        various walks of life like teachers, principals, HODs, children in schools, junior colleges,
                        universities, DCOs,
                        leadership training etc. Here is a quick glance of our heartprint
                    </p>
                </div>
            </div>
            <div class="py-3 bg-ash-1">
                <div class="row gx-1 justify-content-center">
                    <div class="col-md-3">
                        <h2 class="price">18+</h2>
                        <p class="price-desc">states</p>
                    </div>
                    <div class="col-md-3">
                        <h2 class="price">1Lakh </h2>
                        <p class="price-desc">Hearts & </p>
 						<p class="price-desc"> Counting</p>
                    </div>
                    <div class="col-md-3">
                        <h2 class="price">20+</h2>
                        <p class="price-desc">Specialized </p>
						<p class="price-desc">  Programs</p>
                    </div>
                    <div class="col-md-3">
                        <h2 class="price">75+</h2>
                        <p class="price-desc">Organizations</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
         )
}
