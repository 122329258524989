import React from 'react';
import { Card } from 'react-bootstrap';

import img3 from '../../assets/images/upcoming/upcomingimg3.png';
import img4 from '../../assets/images/upcoming/upcomingimg4.png';
import img5 from '../../assets/images/upcoming/upcomingimg5.png';

export default function Upcoming() {
    return (
        <section class="my-5 up-margin" id="upcoming">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <h3 class="mb-2 event-head py-2">Events</h3>
                    <p class="event-desc">Never miss an event, keep an eye out here for our upcoming events</p>
                </div>
            </div>

            <div class="row py-5">
                <div class="col-md-4">
                    <div class="">
                        <div class="">
                            <div class="position-relative">
                            <div class="new-date">29 Apr - 1 May, 2022</div>
                            <img src={img3} alt='Logo' class='card-img-top'/>
                                {/* <div class="position-absolute top-0 end-0  text-center">
                                    <p class="date">29</p>
                                    <p class="month">April - </p>
                                    <p class="date">1</p>
                                    <p class="month">May</p>
                                    <p class="year">2022</p>
                                </div> */}
                            </div>

                        </div>
                        <div class="card-body px-0 ">
                            <h5 class="card-title head-height fw-bold">International Heartfulness Seminar</h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="">
                        <div class="position-relative">
                        <div class="new-date">Jan - May, 2022</div>
                        <img src={img4} alt='Logo' class='card-img-top'/>
                        </div>
                        <div class="card-body px-0">
                            <h5 class="card-title head-height  fw-bold">GOAL: Entrepreneurship Development Program for Skill Development</h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="">
                        <div class="position-relative">
                        <div class="new-date">Jan - June, 2022</div>
                        <img src={img5} alt='Logo' class='card-img-top' />
                        </div>
                        <div class="card-body px-0  ">
                            <h5 class="card-title head-height fw-bold">GROW: Nursery, Landscaping & Gardening course </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
      )
}
