
import React, { useState } from "react";

import axios from "axios";

import moment from "moment";

import { useForm, Controller } from "react-hook-form";

import { ToastContainer, toast } from "react-toastify";

import { Modal, Spinner,Label,Select } from "react-bootstrap";

import IndexPage from "../../pages";

import 'react-toastify/dist/ReactToastify.css';

const config = require("../../data/SiteConfig");




const Bookastay = () => {  
    const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {

    setLoading(true);

    const dataObj = {};
    const todayVal = moment(new Date()).format("MM-DD-YYYY h:mm:ss a");
    
    dataObj.submitteddate = todayVal;
    
    dataObj.firstname = data.firstname;
    dataObj.lastname = data.lastname;
    dataObj.emailid = data.email;
    dataObj.mobileno = data.countrycode + data.phone;
    dataObj.queries = data.queries + data.queries1;
    dataObj.projectname = "HET";

       axios.post(config.contactusUrl, dataObj).
       then((response) => {
        if (response.status === 200) {
          setLoading(false);
          toast.success("Form submitted successfully");
          reset();         
        } else {
          setLoading(false);
          toast.error("something went wrong");
        }
      });
   
  };
  return (
    <div id="Contact">
	  
	   <section class="py-5" >
       <div class="query-container">
         <div class="container">
       <div class="row">
               <div class="col-md-12">
                   <h3 class="form-head">Contact us</h3>
                   <p class="form-desc">Fill this form to stay connected with us</p>
               </div>
           </div>
           </div>
           <div class="container-fluid contactus p-0 m-0">
             <div class="container">
       <div class="row gx-0">
	   <div class="col-md-12">
	           <div class="row gx-1">
			       <div class="col-md-6">
				       <form class="form-class" onSubmit={handleSubmit(onSubmit)}>
                <div class="row">
                    <div class="col-md-6">
                        
                        <input
                           type="text"
                           {...register("firstname", {
                             required: "This field is required",
                             pattern: {
                               value: /^[a-zA-Z ]{2,40}$/,
                               message: "Please enter a valid firstname",
                             },
                           })}
                            name="firstname"  class="form-control shadow-none rounded-0" 
                            placeholder="First Name"
                         />
                         {errors.firstname && (
                           <span className="form-error">
                             {errors.firstname.message}
                           </span>
                         )}

                        <div class="invalid-feedback">
                            Please enter first name.
                        </div>
                        
                    </div>
                    <div class="col-md-6">
                    <input
                           type="text"
                           {...register("lastname", {
                             required: "This field is required",
                             pattern: {
                               value: /^[a-zA-Z ]{2,40}$/,
                               message: "Please enter a valid lastname",
                             },
                           })}
                            name="lastname"  class="form-control shadow-none rounded-0" 
                            placeholder="Last Name"
                         />
                         {errors.lastname && (
                           <span className="form-error">
                             {errors.lastname.message}
                           </span>
                         )}
                        <div class="invalid-feedback">
                            Please enter last name.
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-3">
                        <div class="searchable">
                        <select {...register("countrycode")} class="form-control shadow-none rounded-0" 
                        id="countrycode" name="countrycode">
                       <option value="+91">+91 (India)</option>
                                <option value="+93">+93 (Afghanistan)</option>
                                <option value="+355">+355 (Albania)</option>
                                <option value="+213">+213 (Algeria)</option>
                                <option value="+376">+376 (Andorra)</option>
                                <option value="+244">+244 (Angola)</option>
                                <option value="+1-268">+1-268 (Antigua and Barbuda)</option>
                                <option value="+54">+54 (Argentina)</option>
                                <option value="+374">+374 (Armenia)</option>
                                <option value="+61">+61 (Australia)</option>
                                <option value="+43">+43 (Austria)</option>
                                <option value="+994">+994 (Azerbaijan)</option>
                                <option value="+1-242">+1-242 (Bahamas)</option>
                                <option value="+973">+973 (Bahrain)</option>
                                <option value="+880">+880 (Bangladesh)</option>
                                <option value="+1-246">+1-246 (Barbados)</option>
                                <option value="+375">+375 (Belarus)</option>
                                <option value="+32">+32 (Belgium)</option>
                                <option value="+501">+501 (Belize)</option>
                                <option value="+229">+229 (Benin)</option>
                                <option value="+975">+975 (Bhutan)</option>
                                <option value="+591">+591 (Bolivia)</option>
                                <option value="+387">+387 (Bosnia and Herzegovina)</option>
                                <option value="+267">+267 (Botswana)</option>
                                <option value="+55 ">+55 (Brazil)</option>
                                <option value="+673">+673 (Brunei)</option>
                                <option value="+359">+359 (Bulgaria)</option>
                                <option value="+226">+226 (Burkina Faso)</option>
                                <option value="+257">+257 (Burundi)</option>
                                <option value="+855">+855 (Cambodia)</option>
                                <option value="+237">+237 (Cameroon)</option>
                                <option value="+238">+238 (Cape Verde)</option>
                                <option value="+236">+236 (Central African Republic)</option>
                                <option value="+235">+235 (Chad)</option>
                                <option value="+56">+56 (Chile)</option>
                                <option value="+86">+86 (China)</option>
                                <option value="+57">+57 (Colombia)</option>
                                <option value="+269">+269 (Comoros)</option>
                                <option value="+243">+243 (Democratic Republic of the Congo)</option>
                                <option value="+242">+242 (Republic of the Congo)</option>
                                <option value="+506">+506 (Costa Rica)</option>
                                <option value="+225">+225 (Ivory Coast)</option>
                                <option value="+385">+385 (Croatia)</option>
                                <option value="+53 ">+53 (Cuba)</option>
                                <option value="+357">+357 (Cyprus)</option>
                                <option value="+420">+420 (Czech Republic)</option>
                                <option value="+45">+45 (Denmark)</option>
                                <option value="+253">+253 (Djibouti)</option>
                                <option value="+1-767">+1-767 (Dominica)</option>
                                <option value="+1-809and1-829">+1-809and1-829 (Dominican Republic)</option>
                                <option value="+593">+593 (Ecuador)</option>
                                <option value="+20">+20 (Egypt)</option>
                                <option value="+503">+503 (El Salvador)</option>
                                <option value="+240">+240 (Equatorial Guinea)</option>
                                <option value="+291">+291 (Eritrea)</option>
                                <option value="+372">+372 (Estonia)</option>
                                <option value="+251">+251 (Ethiopia)</option>
                                <option value="+679">+679 (Fiji)</option>
                                <option value="+358">+358 (Finland)</option>
                                <option value="+33">+33 (France)</option>
                                <option value="+241">+241 (Gabon)</option>
                                <option value="+220">+220 (Gambia)</option>
                                <option value="+49">+49 (Germany)</option>
                                <option value="+233">+233 (Ghana)</option>
                                <option value="+30">+30 (Greece)</option>
                                <option value="+1-473">+1-473 (Grenada)</option>
                                <option value="+502">+502 (Guatemala)</option>
                                <option value="+224">+224 (Guinea)</option>
                                <option value="+245">+245 (Guinea-Bissau)</option>
                                <option value="+592">+592 (Guyana)</option>
                                <option value="+509">+509 (Haiti)</option>
                                <option value="+504">+504 (Honduras)</option>
                                <option value="+36">+36 (Hungary)</option>
                                <option value="+354">+354 (Iceland)</option>
                                <option value="+62">+62 (Indonesia)</option>
                                <option value="+98">+98 (Iran)</option>
                                <option value="+964">+964 (Iraq)</option>
                                <option value="+353">+353 (Ireland)</option>
                                <option value="+972">+972 (Israel)</option>
                                <option value="+39">+39 (Italy)</option>
                                <option value="+1-876">+1-876 (Jamaica)</option>
                                <option value="+81">+81 (Japan)</option>
                                <option value="+962">+962 (Jordan)</option>
                                <option value="+254">+254 (Kenya)</option>
                                <option value="+686">+686 (Kiribati)</option>
                                <option value="+850">+850 (North Korea)</option>
                                <option value="+82">+82 (South Korea)</option>
                                <option value="+965">+965 (Kuwait)</option>
                                <option value="+996">+996 (Kyrgyzstan)</option>
                                <option value="+856">+856 (Laos)</option>
                                <option value="+371">+371 (Latvia)</option>
                                <option value="+961">+961 (Lebanon)</option>
                                <option value="+266">+266 (Lesotho)</option>
                                <option value="+231">+231 (Liberia)</option>
                                <option value="+218">+218 (Libya)</option>
                                <option value="+423">+423 (Liechtenstein)</option>
                                <option value="+370">+370 (Lithuania)</option>
                                <option value="+352">+352 (Luxembourg)</option>
                                <option value="+389">+389 (Macedonia)</option>
                                <option value="+261">+261 (Madagascar)</option>
                                <option value="+265">+265 (Malawi)</option>
                                <option value="+60">+60 (Malaysia)</option>
                                <option value="+960">+960 (Maldives)</option>
                                <option value="+223">+223 (Mali)</option>
                                <option value="+356">+356 (Malta)</option>
                                <option value="+692">+692 (Marshall Islands)</option>
                                <option value="+222">+222 (Mauritania)</option>
                                <option value="+230">+230 (Mauritius)</option>
                                <option value="+52">+52 (Mexico)</option>
                                <option value="+691">+691 (Micronesia)</option>
                                <option value="+373">+373 (Moldova)</option>
                                <option value="+377">+377 (Monaco)</option>
                                <option value="+976">+976 (Mongolia)</option>
                                <option value="+382">+382 (Montenegro)</option>
                                <option value="+212">+212 (Morocco)</option>
                                <option value="+258">+258 (Mozambique)</option>
                                <option value="+95">+95 (Myanmar)</option>
                                <option value="+264">+264 (Namibia)</option>
                                <option value="+674">+674 (Nauru)</option>
                                <option value="+977">+977 (Nepal)</option>
                                <option value="+31">+31 (Netherlands)</option>
                                <option value="+64">+64 (New Zealand)</option>
                                <option value="+505">+505 (Nicaragua)</option>
                                <option value="+227">+227 (Niger)</option>
                                <option value="+234">+234 (Nigeria)</option>
                                <option value="+968">+968 (Oman)</option>
                                <option value="+92">+92 (Pakistan)</option>
                                <option value="+680">+680 (Palau)</option>
                                <option value="+507">+507 (Panama)</option>
                                <option value="+675">+675 (Papua New Guinea)</option>
                                <option value="+595">+595 (Paraguay)</option>
                                <option value="+51">+51 (Peru)</option>
                                <option value="+63">+63 (Philippines)</option>
                                <option value="+48">+48 (Poland)</option>
                                <option value="+351">+351 (Portugal)</option>
                                <option value="+974">+974 (Qatar)</option>
                                <option value="+40">+40 (Romania)</option>
                                <option value="+7">+7 (Russia)</option>
                                <option value="+250">+250 (Rwanda)</option>
                                <option value="+1-869">+1-869 (Saint Kitts and Nevis)</option>
                                <option value="+1-758">+1-758 (Saint Lucia)</option>
                                <option value="+1-784">+1-784 (Saint Vincent and the Grenadines)</option>
                                <option value="+685">+685 (Samoa)</option>
                                <option value="+378">+378 (San Marino)</option>
                                <option value="+239">+239 (Sao Tome and Principe)</option>
                                <option value="+966">+966 (Saudi Arabia)</option>
                                <option value="+221">+221 (Senegal)</option>
                                <option value="+381">+381 (Serbia)</option>
                                <option value="+248">+248 (Seychelles)</option>
                                <option value="+232">+232 (Sierra Leone)</option>
                                <option value="+65 ">+65 (Singapore)</option>
                                <option value="+421">+421 (Slovakia)</option>
                                <option value="+386">+386 (Slovenia)</option>
                                <option value="+677">+677 (Solomon Islands0</option>
                                <option value="+252">+252 (Somalia)</option>
                                <option value="+27 ">+27 (South Africa)</option>
                                <option value="+34 ">+34 (Spain)</option>
                                <option value="+94 ">+94 (Sri Lanka)</option>
                                <option value="+249">+249 (Sudan)</option>
                                <option value="+597">+597 (Suriname)</option>
                                <option value="+268">+268 (Swaziland)</option>
                                <option value="+46 ">+46 (Sweden)</option>
                                <option value="+41 ">+41 (Switzerland)</option>
                                <option value="+963">+963 (Syria)</option>
                                <option value="+992">+992 (Tajikistan)</option>
                                <option value="+255">+255 (Tanzania)</option>
                                <option value="+66 ">+66 (Thailand)</option>
                                <option value="+670">+670 (East Timor)</option>
                                <option value="+228">+228 (Togo)</option>
                                <option value="+676">+676 (Tonga)</option>
                                <option value="+1-868">+1-868 (Trinidad and Tobago)</option>
                                <option value="+216">+216 (Tunisia)</option>
                                <option value="+90 ">+90 (Turkey)</option>
                                <option value="+993">+993 (Turkmenistan)</option>
                                <option value="+688">+688 (Tuvalu)</option>
                                <option value="+256">+256 (Uganda)</option>
                                <option value="+380">+380 (Ukraine)</option>
                                <option value="+971">+971 (United Arab Emirates)</option>
                                <option value="+44 ">+44 (United Kingdom)</option>
                                <option value="+1 ">+1 (United States)</option>
                                <option value="+598">+598 (Uruguay)</option>
                                <option value="+998">+998 (Uzbekistan)</option>
                                <option value="+678">+678 (Vanuatu)</option>
                                <option value="+379">+379 (Vatican)</option>
                                <option value="+58 ">+58 (Venezuela)</option>
                                <option value="+84 ">+84 (Vietnam)</option>
                                <option value="+967">+967 (Yemen)</option>
                                <option value="+260">+260 (Zambia)</option>
                                <option value="+263">+263 (Zimbabwe)</option>
                                <option value="+995">+995 (Georgia)</option>
                                <option value="+886">+886 (Taiwan)</option>
                                <option value="+374-9">+374-97 (Republic of Artsakh)</option>
                                <option value="+90-39">+90-392 (Northern Cyprus)</option>
                                <option value="+373-5">+373-533 (Tiraspol)</option>
                                <option value="+672">+672 (Antarctica)</option>
                                <option value="+687">+687 (New Caledonia)</option>
                                <option value="+689">+689 (French Polynesia)</option>
                                <option value="+262">+262 (Reunion)</option>
                                <option value="+590">+590 (Saint Barthelemy)</option>
                                <option value="+508">+508 (Saint Pierre and Miquelon)</option>
                                <option value="+681">+681 (Wallis and Futuna)</option>
                                <option value="+682">+682 (Cook Islands)</option>
                                <option value="+683">+683 (Niue)</option>
                                <option value="+690">+690 (Tokelau)</option>
                                <option value="+1-264">+1-264 (Anguilla)</option>
                                <option value="+1-441">+1-441 (Bermuda)</option>
                                <option value="+246">+246 (British Indian Ocean Territory)</option>
                                <option value="+1-284">+1-284 (British Virgin Islands)</option>
                                <option value="+1-345">+1-345 (Cayman Islands)</option>
                                <option value="+500">+500 (Falkland Islands)</option>
                                <option value="+350">+350 (Gibraltar)</option>
                                <option value="+1-664">+1-664 (Montserrat)</option>
                                <option value="+290">+290 (Saint Helena)</option>
                                <option value="+1-649">+1-649 (Turks and Caicos Islands)</option>
                                <option value="+1-670">+1-670 (Northern Mariana Islands)</option>
                                <option value="+1-787">+1-787and1-939 (Puerto Rico)</option>
                                <option value="+1-684">+1-684 (American Samoa)</option>
                                <option value="+1-671">+1-671 (Guam)</option>
                                <option value="+1-340">+1-340 (U.S. Virgin Islands)</option>
                                <option value="+852">+852 (Hong Kong)</option>
                                <option value="+853">+853 (Macau)</option>
                                <option value="+298">+298 (Faroe Islands)</option>
                                <option value="+299">+299 (Greenland)</option>
                                <option value="+594">+594 (French Guiana)</option>
                                <option value="+596">+596 (Martinique)</option>
                                <option value="+358-1">+358-18 (Aland Islands)</option>
                                <option value="+297">+297 (Aruba)</option>
                                <option value="+599">+599 (Curacao)</option>
                                <option value="+47 ">+47 (Norway)</option>
                                <option value="+247">+247 (Ascension Island)</option>
                                <option value="+970">+970 (Palestine)</option>
                      </select>
                           </div>
                          
                    </div>
                   
                    <div class="col-md-4">
                         <input
                           type="number"
                           {...register("phone", {
                             required: "This field is required",
                             pattern: {
                               value: /^(\+91[\s]?)?[0]?(91)?[6789]\d{9}$/,
                               message: "Please enter a valid Mobile Number",
                             },
                           })}
                           class="form-control shadow-none rounded-0" placeholder="Mobile" 
                           id="phone"
                         />
                        {errors.phone && (
                           <span className="form-error">
                             {errors.phone.message}
                           </span>
                         )} 
                        <div class="invalid-feedback">
                            Please enter Mobile Number
                        </div>
                    </div>
                    <div class="col-md-5">
                      
                            <input
                           type="text"
                           {...register("email", {
                             required: "Enter your Email",
                             pattern: {
                               value:
                                 /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                               message: "Please enter a valid email",
                             },
                           })}
                           class="form-control shadow-none rounded-0" 
                        placeholder="Mail id"
                           name="email"
                         />
                         {errors.email && (
                           <span className="form-error">
                             {errors.email.message}
                           </span>
                         )}
                        <div class="invalid-feedback">
                            Please enter email id
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        
                            <input
                           type="text"
                           {...register("queries", {
                             required: "This field is required",
                             maxlength:200,
                             pattern: {
                               message: "Please enter a valid queries",
                             },
                           })}
                            name="queries"  class="form-control shadow-none rounded-0"
                            placeholder="What are you looking for?"
                         />
                         {errors.queries && (
                           <span className="form-error">
                             {errors.queries.message}
                           </span>
                         )}
                    </div>
                    
                </div>
                <div class="row">
                    <div class="col-md-12">
                        
                            <input
                           type="text"
                           {...register("queries1", {
                            maxlength: 200,
                               pattern: {
                               message: "Please enter a valid queries",
                             },
                           })}
                            name="queries1"  class="form-control shadow-none rounded-0"
                            
                         />
                    </div>
                    
                </div>
                
                <div class="row mt-4">
                    <div class="col-md-8">
                    <input type="submit" value="Send" class="btn btn-primary btn-rounded px-5"/>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-8">
                    <p><b>Heartfulness Education Trust</b><br></br>
                    13-110, Kanha Shanti Vanam,<br></br>
                    Kanha Village, Nandigama Mandal,<br></br>
                    Ranga Reddy District,<br></br>
                    Telangana - 509325, India.<br></br>
                    <a href="mailto:education@heartfulness.org">education@heartfulness.org</a></p>
                    </div>
                </div>
            </form>
				   </div>
				   <div class="col-md-6 offer-desc">
           {/* <iframe src="https://www.google.com/maps/place/Heartfulness+Learning+Center/@17.1854761,78.2176922,18z/data=!4m5!3m4!1s0x3bcbc7ece1f7e355:0x7eb1d860703d3bf2!8m2!3d17.1860027!4d78.2184595" 
           allowfullscreen="" loading="lazy" className="mapclass"
            referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1511.2769135477542!2d78.2171685!3d17.185114700000003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x815c118e45448ef7!2sHeartfulness%20Education%20Trust!5e1!3m2!1sen!2sin!4v1649081616992!5m2!1sen!2sin" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="mapclass"></iframe>
           </div>
				   
			  </div>
		</div>
	</div>
  </div>
  </div>       
           

             
       </div>
   </section>
   <div>
       <ToastContainer
         position="top-right"
         autoClose={3000}
         newestOnTop={false}
         hideProgressBar
         closeOnClick
         rtl={false}
       />
       
     </div>
   </div>

  );
};
export default Bookastay;

