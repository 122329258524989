import React, { useState } from "react";
import { Card , Button , Collapse } from 'react-bootstrap';

import Infrastruture from '../../assets/images/Facilities/Infrastruture.png'
import Food from '../../assets/images/Facilities/Food.png'
import Medical from '../../assets/images/Facilities/Medical.png'
import Recreation from '../../assets/images/Facilities/Recreation.png'

export default function Facilities() {
    const [Infrastrutureopen, setInfrastrutureOpen] = useState(false);
    const [Foodopen, setFoodOpen] = useState(false);
    const [Medicalopen, setMedicalOpen] = useState(false);
    const [Recreationopen, setRecreationOpen] = useState(false);
    
    return (
        <section class="py-5" id="facilities">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <h3 class="py-2 f-head">Facilities</h3>
                    <p class="f-desc">Here is a quick glance at the infrastructure and facilities at HET's
                        headquarters: 
                    </p>
                    <p class="f-desc"> Kanha Shanti Vanam, Hyderabad</p>
                </div>
            </div>

            <div class="row gx-2  py-5 fac-bottom">
                <div class="col-md-3">
                    <div class="">
                           <img src={Infrastruture} alt='Logo' class='card-img-top img-fluid'/>
                        <div class="card-body offer-desc p-2">
                            <h5 class="card-title ">Infrastructure</h5>
                            <Card className="card-collapse"> 
                            <p class="card-text desc-card">Kanha Shanti Vanam is the world headquarters of the Heartfulness Institute. 
                            { Infrastrutureopen &&  
                           " The ashram is an ecological paradise, a place for spiritual retreat, training centre with state of the art facilities like world's largest meditation hall, well equipped auditoriums and conference halls, essential oil distillation plant, goshala, comfort dormitory based accommodation, metal and engineering works, rain forrest, construction and civil works etc where you can experience simple living in-tune with inner and outer nature. The center also received the Indian Green Building Council (IGBC) Platinum award in 2019 for following world class environment friendly practices becoming the first meditation center in the world to achieve the feat."}
                           <a class="anchor" onClick={() => setInfrastrutureOpen(!Infrastrutureopen)}> {Infrastrutureopen ? "...View less" : "...View more" }</a>
                           </p> 
                            </Card>
                           
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="">
                    <img src={Food} alt='Logo' class='card-img-top img-fluid'/>
                        <div class="card-body offer-desc p-2">
                            <h5 class="card-title">Food</h5>
                            <Card  className="card-collapse"> 
                            <p class="card-text desc-card"> Kanha houses a large dining hall that provides simple and light meals for free 3 
                            { Foodopen &&  
                           " times a day, 365 days of the year. Satvik and healthy vegetarian food is cooked and served to all residents and visitors who wish to dine here. Simple and healthy vegetarian food is cooked and served with love to all residents and visitors who wish to dine here."}
                           <a class="anchor" onClick={() => setFoodOpen(!Foodopen)}> {Foodopen ? "...View less" : "...View more" }</a>
                           </p>
                            </Card>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="">
                    <img src={Medical} alt='Logo' class='card-img-top img-fluid'/>
                        <div class="card-body offer-desc p-2">
                            <h5 class="card-title">Medical</h5>
                            <Card  className="card-collapse"> 
                            <p class="card-text desc-card">The Kanha Medical Center (KMC) is a full time facility run by a certified team   
                            { Medicalopen &&  
                           " of doctors and paramedics, offering medical services to all residents and visitors of Kanha as well as to those from surrounding villages."}
                           <a class="anchor" onClick={() => setMedicalOpen(!Medicalopen)}> {Medicalopen ? "...View less" : "...View more" }</a>
                           </p> 
                            </Card>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="">
                    <img src={Recreation} alt='Logo' class='card-img-top img-fluid'/>
                        <div class="card-body offer-desc p-2">
                            <h5 class="card-title">Recreation</h5>
                            <Card  className="card-collapse"> 
                            <p class="card-text desc-card"> Kanha is one of the best places for recreation with a gymnasium, carefully 
                            { Recreationopen &&  
                           " designed world class play area for children, yoga studio, wellness center, sports area with multiple courts, pottery studio, nursery and plantation etc where you can refresh yourself."}
                           <a class="anchor" onClick={() => setRecreationOpen(!Recreationopen)}> {Recreationopen ? "...View less" : "...View more" }</a>
                           </p> 
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
     
    </section>
    )
}
